<template>
    <!-- 콘솔/클라이언트 공용 컴포넌트입니다. 소스코드 임의로 수정하지 말아주세요. -->
    <v-sheet width="100%" style="position: relative">
        <v-fade-transition leave-absolute>
            <v-hover v-if="src" v-slot="{ hover }">
                <v-img ref="showroom" v-bind="{ ...$attrs, src }" :aspect-ratio="aspectRatio" style="position: relative; cursor: pointer" @click="addItem" @resize="setShowroomSize">
                    <v-tooltip v-if="editable_image">
                        <template v-slot:activator="{ attrs, on }">
                            <v-fade-transition>
                                <v-btn v-show="hover" v-bind="attrs" v-on="on" absolute right bottom fab color="white" elevation="4" class="mb-10" @click.stop="addImage">
                                    <v-icon>mdi-image</v-icon>
                                </v-btn>
                            </v-fade-transition>
                        </template>
                    </v-tooltip>
                    <v-fade-transition v-for="(item, index) in form.items" :key="item._id">
                        <showroom-item v-model="form.items[index]" v-show="showsItems" v-bind="{ z: index, editable, showroom, target, attrs_input }" v-on="{ remove, input: emit }" :ref="item._id" />
                    </v-fade-transition>
                </v-img>
            </v-hover>
            <v-responsive v-else v-bind="{ ...$attrs }" :aspect-ratio="aspectRatio">
                <v-card class="fill-height" @click="addImage">
                    <v-overlay absolute z-index="0" color="grey">
                        <v-icon x-large>mdi-image</v-icon>
                    </v-overlay>
                </v-card>
            </v-responsive>
        </v-fade-transition>
        <v-file-input ref="image-input" v-show="false" @change="setImage" />
    </v-sheet>
</template>

<script>
import { initShowroom, initShowroomItem } from "@/assets/variables";

import ShowroomItem from "./showroom-item.vue";

export default {
    components: {
        ShowroomItem,
    },
    props: {
        value: { type: Object, default: initShowroom },

        target: { type: String, default: null },
        attrs_input: { type: Object, default: () => ({}) },

        editable: { type: Boolean, default: false },
        editable_image: { type: Boolean, default: false },
    },
    data: () => ({
        form: initShowroom(),

        showroom: {
            w: 100,
            h: 100,
        },
        showsItems: true,
    }),
    computed: {
        src() {
            if (this.form.image instanceof File) return URL.createObjectURL(this.form.image);
            else return this.form.image?.url;
        },
        aspectRatio() {
            const { width = 1, height = 1 } = this.form.ratio;
            return width / height;
        },
    },
    created() {
        window.addEventListener("resize", this.setShowroomSize);
    },
    destroyed() {
        window.removeEventListener("resize", this.setShowroomSize);
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        init() {
            this.sync();
            setTimeout(this.setShowroomSize, 250);
        },
        sync() {
            this.form = initShowroom(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
        addImage() {
            this.$refs["image-input"]?.$el?.getElementsByTagName("input")?.[0]?.click();
        },
        setImage(file) {
            this.form = initShowroom({ ...this.value, image: file });
            this.setAspectRatio();
            this.emit();

            this.$refs["image-input"]?.$on?.click?.clear();
        },
        setAspectRatio() {
            const image = new Image();
            image.onload = () => {
                const { width = 1, height = 1 } = image;
                this.form = initShowroom({ ...this.value, ratio: { width, height } });
                this.emit();
                this.$nextTick(this.setShowroomSize);
            };
            image.src = this.src;
        },
        addItem(event) {
            if (!this.editable) {
                this.showsItems = !this.showsItems;
                return;
            }
            if (!confirm("아이템을 추가하시겠습니까?")) return;

            const { w, h } = this.showroom;
            const { layerX, layerY } = event;
            const x = (layerX / w) * 100;
            const y = (layerY / h) * 100;

            this.form = initShowroom({ ...this.value, items: [...this.value.items, initShowroomItem({ x, y })] });
            this.emit();
        },
        remove(target) {
            this.form = initShowroom({ ...this.value, items: this.form.items.filter(({ tempId }) => tempId != target?.tempId) });
            this.emit();
        },
        setShowroomSize() {
            const { clientWidth: w = 100, clientHeight: h = 100 } = this.$refs["showroom"]?.$el || {};
            this.showroom = { w, h };
        },
        closeItems() {
            this.form.items.forEach(({ _id }) => (this.$refs[_id][0].shows = false));
        },
    },
};
</script>

<style>
/* 콘솔/클라이언트 공용 컴포넌트입니다. 소스코드 임의로 수정하지 말아주세요. */
</style>
