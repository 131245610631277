<template>
    <!-- 콘솔/클라이언트 공용 컴포넌트입니다. 소스코드 임의로 수정하지 말아주세요. -->
    <vue-draggable-resizable v-bind="attrs" @dragging="onDragging" @dragstop="onDragStop">
        <v-menu v-model="shows" top :nudge-top="40" :close-on-content-click="false" content-class="rounded">
            <template #activator="{ attrs, on }">
                <v-btn small icon active-class="white" style="background: rgba(255, 255, 255, 0.25); transform: translate(-50%, -50%)" v-bind="attrs" v-on="on" @click.stop>
                    <v-icon small color="white">mdi-circle</v-icon>
                </v-btn>
            </template>
            <template v-if="editable">
                <v-card>
                    <v-card-title>
                        아이템 정보
                        <v-spacer />
                        <v-icon @click="shows = false">mdi-close</v-icon>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                        <autocomplete-product v-model="inputs._product" label="상품" v-bind="attrs_input" @input="emit" />
                        <!-- <v-text-field v-model="inputs.name" label="항목 이름" v-bind="attrs_input" @input="emit" />
                    <v-text-field v-model="inputs.desc" label="항목 상세" v-bind="attrs_input" class="mt-3" @input="emit" />
                    <v-text-field v-model="inputs.href" label="항목 링크" placeholder="https://www.uxi.co.kr" v-bind="attrs_input" class="mt-3" @input="emit" /> -->
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-btn outlined color="remove" @click="$emit('remove', value)">삭제하기</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
            <template v-else>
                <v-card class="px-5 py-1" :to="`/shop/products/${value?._product}`" v-bind="{ target }">
                    <product-item :product="value?.product" isLink hideDivider />
                </v-card>
                <!-- <v-card link :href="value.href">
                    <shop-product-item :value="value.produc" />
                    <v-card-title> {{ value.name }} <v-spacer /> <i class="icon icon-chevron-right-small"></i> </v-card-title>
                    <v-divider />
                    <v-card-text>{{ value.desc }}</v-card-text>
                </v-card> -->
            </template>
        </v-menu>
    </vue-draggable-resizable>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";

import ProductItem from "@/components/client/mypage/product/product-item.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";

export default {
    components: {
        VueDraggableResizable,

        ProductItem,
        AutocompleteProduct,
    },
    props: {
        value: { type: Object, default: () => ({}) },

        z: { type: Number, default: 0 },
        target: { type: String, default: null },
        showroom: { type: Object, default: () => ({ w: 100, h: 100 }) },
        attrs_input: { type: Object, default: () => ({}) },

        editable: { type: Boolean, default: false },
    },
    computed: {
        attrs() {
            const { z, value, showroom, editable } = this;
            const x = (value.x / 100) * showroom.w;
            const y = (value.y / 100) * showroom.h;
            return { x, y, z, w: 1, h: 1, resizable: false, draggable: editable };
        },
    },
    data: () => ({
        shows: false,
        inputs: {
            name: null,
            desc: null,
            href: null,
        },
    }),
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.inputs = { ...this.inputs, ...this.value };
        },
        emit() {
            this.$emit("input", this.inputs);
        },
        onDragging() {
            if (this.shows) this.shows = false;
        },
        onDragStop(x, y) {
            const { w, h } = this.showroom;
            x = (x / w) * 100;
            y = (y / h) * 100;

            this.inputs = { ...this.inputs, x, y };
            this.shows = true;
            this.emit();
        },
    },
    watch: {
        "value.name"() {
            this.sync();
        },
        "value.desc"() {
            this.sync();
        },
        "value.href"() {
            this.sync();
        },
    },
};
</script>

<style>
/* 콘솔/클라이언트 공용 컴포넌트입니다. 소스코드 임의로 수정하지 말아주세요. */
</style>
