var render = function render(){
  var _vm$value, _vm$value2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-draggable-resizable', _vm._b({
    on: {
      "dragging": _vm.onDragging,
      "dragstop": _vm.onDragStop
    }
  }, 'vue-draggable-resizable', _vm.attrs, false), [_c('v-menu', {
    attrs: {
      "top": "",
      "nudge-top": 40,
      "close-on-content-click": false,
      "content-class": "rounded"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticStyle: {
            "background": "rgba(255, 255, 255, 0.25)",
            "transform": "translate(-50%, -50%)"
          },
          attrs: {
            "small": "",
            "icon": "",
            "active-class": "white"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "white"
          }
        }, [_vm._v("mdi-circle")])], 1)];
      }
    }]),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.editable ? [_c('v-card', [_c('v-card-title', [_vm._v(" 아이템 정보 "), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-card-text', [_c('autocomplete-product', _vm._b({
    attrs: {
      "label": "상품"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.inputs._product,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "_product", $$v);
      },
      expression: "inputs._product"
    }
  }, 'autocomplete-product', _vm.attrs_input, false))], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "remove"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('remove', _vm.value);
      }
    }
  }, [_vm._v("삭제하기")])], 1)], 1)] : [_c('v-card', _vm._b({
    staticClass: "px-5 py-1",
    attrs: {
      "to": `/shop/products/${(_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value._product}`
    }
  }, 'v-card', {
    target: _vm.target
  }, false), [_c('product-item', {
    attrs: {
      "product": (_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.product,
      "isLink": "",
      "hideDivider": ""
    }
  })], 1)]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }