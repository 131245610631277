var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "width": "100%"
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_vm.src ? _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var hover = _ref.hover;
        return [_c('v-img', _vm._b({
          ref: "showroom",
          staticStyle: {
            "position": "relative",
            "cursor": "pointer"
          },
          attrs: {
            "aspect-ratio": _vm.aspectRatio
          },
          on: {
            "click": _vm.addItem,
            "resize": _vm.setShowroomSize
          }
        }, 'v-img', Object.assign({}, _vm.$attrs, {
          src: _vm.src
        }), false), [_vm.editable_image ? _c('v-tooltip', {
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var attrs = _ref2.attrs,
                on = _ref2.on;
              return [_c('v-fade-transition', [_c('v-btn', _vm._g(_vm._b({
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: hover,
                  expression: "hover"
                }],
                staticClass: "mb-10",
                attrs: {
                  "absolute": "",
                  "right": "",
                  "bottom": "",
                  "fab": "",
                  "color": "white",
                  "elevation": "4"
                },
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                    return _vm.addImage.apply(null, arguments);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)];
            }
          }], null, true)
        }) : _vm._e(), _vm._l(_vm.form.items, function (item, index) {
          return _c('v-fade-transition', {
            key: item._id
          }, [_c('showroom-item', _vm._g(_vm._b({
            directives: [{
              name: "show",
              rawName: "v-show",
              value: _vm.showsItems,
              expression: "showsItems"
            }],
            ref: item._id,
            refInFor: true,
            model: {
              value: _vm.form.items[index],
              callback: function ($$v) {
                _vm.$set(_vm.form.items, index, $$v);
              },
              expression: "form.items[index]"
            }
          }, 'showroom-item', {
            z: index,
            editable: _vm.editable,
            showroom: _vm.showroom,
            target: _vm.target,
            attrs_input: _vm.attrs_input
          }, false), {
            remove: _vm.remove,
            input: _vm.emit
          }))], 1);
        })], 2)];
      }
    }], null, false, 3622582113)
  }) : _c('v-responsive', _vm._b({
    attrs: {
      "aspect-ratio": _vm.aspectRatio
    }
  }, 'v-responsive', Object.assign({}, _vm.$attrs), false), [_c('v-card', {
    staticClass: "fill-height",
    on: {
      "click": _vm.addImage
    }
  }, [_c('v-overlay', {
    attrs: {
      "absolute": "",
      "z-index": "0",
      "color": "grey"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("mdi-image")])], 1)], 1)], 1)], 1), _c('v-file-input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "image-input",
    on: {
      "change": _vm.setImage
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }